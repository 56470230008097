.select-css {
	display: block;
	font-size: 1rem;
	font-family: sans-serif;
	font-weight: 500;
	color: #101011;
	line-height: 1.4;
	padding: .65em 2.5em .65em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: none;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: 0;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url("data:image/svg+xml,%3Csvg className='fill-current h-4 w-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' /%3E%3C/svg%3E");
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: 1.5rem;
}
.select-css::-ms-expand {
	display: none;
}
.select-css:hover {
	border-color: #888;
}
.select-css:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
.select-css option {
	font-weight:normal;
}

*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
	background-position: left .7em top 50%, 0 0;
	padding: .6em .8em .5em 1.4em;
}

.select-css:disabled, .select-css[aria-disabled=true] {
	color: graytext;
	background-image: url("data:image/svg+xml,%3Csvg className='fill-current h-4 w-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' /%3E%3C/svg%3E");
}
.select-css:disabled:hover, .select-css[aria-disabled=true] {
	border-color: #aaa;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: #142328;
	transition: background-color 5000s ease-in-out 0s;
}

.form-row {
	display: flex;
	justify-content: center;
	align-items: center;
}

.form-field {
	width: 100%;
}

.form-field input {
	width: 100%;
	margin: 0.25rem 0;
	outline: none;
}

.form-field.field-agreement {
	display: flex;
	align-items: center;
	justify-content: center;
}
.form-field input[type='checkbox'] {
	height: auto;
	width: auto;
}

.form-field .agreement {
	font-size: 0.9rem;
	text-align: left;
	max-width: 460px;
}

.form-field.notice-agreement {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.form-submitting {
	margin-top: 2rem;
}

.is-valid-false {
  border: 2px solid red;
}

.error {
	position: absolute;
	color: white;
	text-align: center;
	margin-top: 0.5rem;
	font-size: 1rem;
	width: 100%;
}
