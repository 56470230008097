@font-face {
	font-family: 'Uber Move';
	src: url('./assets/fonts/UberMoveText-Regular.woff2') format('woff2'),
			url('./assets/fonts/UberMoveText-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Uber Move';
	src: url('./assets/fonts/UberMoveText-Medium.woff2') format('woff2'),
			url('./assets/fonts/UberMoveText-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Uber Move';
	src: url('./assets/fonts/UberMoveText-Bold.woff2') format('woff2'),
			url('./assets/fonts/UberMoveText-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body {
  background: #003D28;
	margin: 0;
	font-family: 'Uber Move', Helvetica Neue,Helvetica,Arial,sans-serif;
	font-size: 1rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	font-weight: 400;
	line-height: 1.35;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background-bottom {
  height: 40vh;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: url('./assets/images/bg-waves-mobile.svg') center bottom no-repeat;
  background-size: cover;
}

.inner-content {
  min-width: 100%;
}

@media screen and (min-width: 540px) {
  .background-bottom {
    height: 40vh;
    position: fixed;
    bottom: -120px;
    left: 0;
    width: 100vw;
    background: url('./assets/images/bg-waves-desktop.svg') center bottom no-repeat;
    background-size: cover;
  }
}

button, button[type='submit']{
  @apply rounded-full bg-white px-10 py-3.5 text-black font-medium;
  min-width: 200px;
  transition: all 0.3s;
}

button:hover {
  @apply bg-black text-white;
  transition: all 0.3s;
}


button.inactive,
.btn.inactive,
.submit-button.inactive{
	pointer-events: not-allowed;
	opacity: 0.5;
	cursor: not-allowed;
}

input {
  @apply rounded-full border-0 text-black bg-white py-3.5 px-6 font-medium;
  width: 200px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #101011;
	opacity: 0.3;
}
::-moz-placeholder { /* Firefox 19+ */
	color: #101011;
	opacity: 0.3;
}
:-ms-input-placeholder { /* IE 10+ */
	color: #101011;
	opacity: 0.3;
}
:-moz-placeholder { /* Firefox 18- */
	color: #101011;
	opacity: 0.3;
}
